more-form {
  @more-form-drag-border-size: 3px;

  .more-form-orderable {
    padding: 12px !important;
  }

  div.item input, div.item textarea {
    width: 100%;
  }

  .list:last-child.card {
    margin-bottom: 0;
  }

  .placeholder-dropping-item {
    height: 60px;
    background: #fff;
    border: @more-form-drag-border-size dashed @brand !important;
    border-radius: 5px;
  }

  .drag-drop .more-form-item {

    &.active {
      position: relative;
      z-index: @z-index-more-form-item-active;
    }

    &:hover:not(.active) {
      position: relative;
      z-index: 3 !important;
      cursor: move;
      outline: @more-form-drag-border-size dashed @brand;

      .form-field-actions {
        display: inline-block;
      }

      .more-field-controls {
        display: block;
      }
    }

    &.field-publish-error {
      border: @more-form-drag-border-size solid @error;
      .easeIn();
    }
  }

  .drag-drop {
    min-height: 200px;
    .field-wrapper.list.card {
      position: static;
      min-height: 40px;
      margin: 0;
    }
  }

  .more-field-controls {
    display: none;
    padding-top: 10px;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    z-index: 3;
    height: 0;
    text-align: center;

    a {

      &.more-field-control-edit {
        position: absolute;
        left:0;
        top: 0;
        border-radius: 0 0 12px 0;
        background: @brand;
      }

      &.more-field-control-delete {
        position: absolute;
        right:0;
        top: 0;
        background-color: white;
        i {
          color : @brand;
        }
        border-bottom: 1px solid @brand;
        border-left: 1px solid @brand;
        border-radius: 0 0 0 12px;
      }
      cursor: pointer;

      max-width: 40px;
      i {
        color: white;
        font-size: 1.5em;
        margin: 9px;
        display: inline-block;
      }
    }
  }
}
