.more-resource-list .more-resource-item {
  float: left;
  position: relative;
}

.more-resource-item {
  width: @more-resource-item-height;
  height: @more-resource-item-height;
  border: 1px solid @more-resource-item-border-color;
  cursor: pointer;

  color: #aaa;

  &:hover {
    text-decoration: none;
    background: darken(#fff, 10%);
  }

  margin-left: @more-resource-item-margin;
  margin-bottom: @more-resource-item-margin;

}

.more-resource-item-remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.more-resource-item-image {
  display: block;
  margin: auto;
  text-align: center;
  padding-top: @more-resource-item-margin;
  height: 100px;
}
