.user-details {
  text-align: right;
  padding-right: @grid-gutter-width;
}

.btn-user-details-wrapper {
  .btn-group();
  margin-top: 12px;

  .btn-user-details {
    .more-btn(lighten(@header-background, 5%));
  }
}

