.menu-picker {

  height: 100%;
  overflow: hidden;
  padding-bottom: 130px;

  .menu-picker-search-wrapper-template {
    .search-wrapper();
    min-height: 94px;
  }

  .menu-picker-search-wrapper {
    .search-wrapper();
  }

  .menu-picker-language-selector {
    padding-left: 12px;
  }

  .menu-picker-content {
    list-style: none;
    padding: 0;

    // allow scrolling
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

    .panel, .panel-heading, li {
      margin: 0;
      border-radius: 0;
      color: @fontdark;
      cursor: pointer;

      i {
        padding-top: 3px;
      }
    }

    .panel-title {
      font-size: 14px;
    }

    .panel-heading {
      min-height: @item-height;
      background: @dark4;
      padding-top: 15px;
      &.active, &:hover {
        .item-active();
      }
      .panel-title .panel-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .menu-picker-item .bs-callout {
      padding-left: 7px; // normally 15, compensates 8px callout
      border-width: 8px;
    }

    li, .panel {
      white-space: nowrap;
      border: none;
    }

    .panel li {
      padding-top: 15px;
      min-height: @item-height;
      background: @light4;
      font-weight: 300;
      font-size: 13px;
      &.active, &:hover {
        .item-active();
      }
    }

    ul {
      margin: 0;
      border-top: 0 !important;
    }

    .menu-picker-item-add {
      color: @brand;
    }

  }

  .item-active {
    background: @brand !important;
    color: @light2;
  }

  .menu-item-badge {
    .badge();
    background-color: #aeaeae;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding-top: 12px;
    margin-top: -8px;
    color: @dark1;
    font-weight: normal;
  }
}
