body.intro-page {
  background-image: url('../../images/segments/background.png');
  height: 100%;
  width: 100%;
  background-size: cover;
  background-clip: border-box;
  background-attachment: fixed;
}

entry-view {

  &.entry-view-xl {
    .entry-view-container .entry-view-block {
      .make-md-column(10);
      .make-md-column-offset(1);
    }
  }
  &.entry-view-sm {
    .entry-view-container .entry-view-block {
      .make-md-column(4);
      .make-md-column-offset(4);
    }
  }

  &.entry-view-md {
    .entry-view-container .entry-view-block {
      .make-md-column(6);
      .make-md-column-offset(3);
    }
  }

  &.entry-view-lg {
    .entry-view-container .entry-view-block {
      .make-md-column(8);
      .make-md-column-offset(2);
    }
  }

  margin-top: @grid-gutter-width;

  .entry-view-container {
    .make-row();

    .entry-view-block .entry-view-content {
      border: 1px solid @light5;
      background: @whitetransparent;
      border-radius: @border-radius-large;
      padding: 50px;
    }
  }
}

.more-login-btn {
  .btn-block();
  margin-bottom: 10px;
}

.more-change-password-btn {
  .btn-block();
}

a#more-language-switch-login {
  color: @dark8;

  .active {
    font-weight: bold;
    color: @dark2;
  }
}

.entry-view-logo img {
  max-width: 80%;
  max-height: 250px;
  padding: 30px 0;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
  .entry-view-logo img {
    max-width: 50%;
    max-height: 400px;
    padding: 80px 0;
  }

}