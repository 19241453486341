.menu-picker {

  .menu-picker-content {
    .panel-heading .panel-title .collection-name {
      width: 215px;
    }
    .panel {
      li:not(.list-group-item):not(.nestedSortable-placeholder) {
        display: none;
      }

      li.nestedSortable-placeholder {
        background: lighten(@brand, 10%);
      }

      li {
        .reorder-handle {
          visibility: hidden;
        }

        &:hover .reorder-handle {
          visibility: visible;
        }
      }
    }
  }
}