.bs-callout(@color, @bgcolor: lighten(@color, 35%)) {
  display: block;
  border-left: 5px solid @color;
  background-color: @bgcolor;
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    color: @color;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code, .highlight {
    background-color: #fff;
  }
}

.bs-callout-primary {
  .bs-callout(@brand-primary, lighten(@brand-primary, 45%));
}

.bs-callout-danger {
  .bs-callout(@brand-danger);
}

.bs-callout-warning {
  .bs-callout(@brand-warning, lighten(@brand-warning, 30%));
}

.bs-callout-info {
  .bs-callout(@brand-info);
}

.bs-callout-success {
  .bs-callout(@brand-success);
}

.bs-callout-unpublished {
  .bs-callout(@action-color, rgba(0,0,0,0));
}

.bs-callout-updated {
  .bs-callout(@brand-primary, rgba(0,0,0,0));
}