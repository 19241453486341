.nestedSortable-list {
  list-style: none;
}

.nestedSortable-handle {
  cursor: move;
  display: block;
  margin-left: 10px;
  text-decoration: none;
}

.nestedSortable-handle:hover {
  font-weight: bold;
}

.nestedSortable-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.nestedSortable-drag {
  position: absolute;
  pointer-events: none;
  z-index: 99999;
  opacity: .8;
}
