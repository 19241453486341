.readonly-info {
  margin-top: 30px;

  .row {
    margin: 0;
  }

  .readonly-info-header {
    color: @dark2;
  }

  .readonly-info-key {
    padding-left: 0;
    margin-top: 5px;
    height: 30px;
    width: 120px;
    color: @dark3;
  }

  .readonly-info-value {
    font-size: 13px;
    width: 195px;
    min-height: 30px;
    height: auto;
    padding-top: 7px;
    background-color: @light3;
    color: @fontdark;

    &.bs-callout {
      padding-left: 10px; // normally 15, compensates 5px callout
    }
  }

  &.wide{
    margin-left: 40px;
    width: 350px;
    .readonly-info-key {
      width: 120px;
    }
    .readonly-info-value {
      width: 300px;
    }
  }
}