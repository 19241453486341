.editable-click,
a.editable-click {
  color: inherit !important;
  border-bottom: dotted 1px @fontdark !important;
  cursor: pointer;
}

.editable-click:hover,
a.editable-click:hover {
  color: darken(@brand, 20%) !important;
  border-bottom-color: darken(@brand, 20%) !important;
}
