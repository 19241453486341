// All
.ma-xxl   { margin: @spacing-xxl; }
.ma-xl    { margin: @spacing-xl;  }
.ma-l     { margin: @spacing-l;   }
.ma-m     { margin: @spacing-m;   }
.ma-s     { margin: @spacing-s;   }
.ma-xs    { margin: @spacing-xs;  }
.ma-none  { margin: 0; }
.ma-auto  { margin: auto; }

// Top
.mt-xxl   { margin-top: @spacing-xxl; }
.mt-xl    { margin-top: @spacing-xl; }
.mt-l     { margin-top: @spacing-l; }
.mt-m     { margin-top: @spacing-m; }
.mt-s     { margin-top: @spacing-s; }
.mt-xs    { margin-top: @spacing-xs; }
.mt-none  { margin-top: 0; }
.mt-auto  { margin-top: auto; }

// Right
.mr-xxl   { margin-right: @spacing-xxl; }
.mr-xl    { margin-right: @spacing-xl; }
.mr-l     { margin-right: @spacing-l; }
.mr-m     { margin-right: @spacing-m; }
.mr-s     { margin-right: @spacing-s; }
.mr-xs    { margin-right: @spacing-xs; }
.mr-none  { margin-right: 0; }
.mr-auto  { margin-right: auto; }

// Bottom
.mb-xxl   { margin-bottom: @spacing-xxl; }
.mb-xl    { margin-bottom: @spacing-xl; }
.mb-l     { margin-bottom: @spacing-l; }
.mb-m     { margin-bottom: @spacing-m; }
.mb-s     { margin-bottom: @spacing-s; }
.mb-xs    { margin-bottom: @spacing-xs; }
.mb-none  { margin-bottom: 0 }
.mb-auto  { margin-bottom: auto; }

// Left
.ml-xxl   { margin-left: @spacing-xxl; }
.ml-xl    { margin-left: @spacing-xl; }
.ml-l     { margin-left: @spacing-l; }
.ml-m     { margin-left: @spacing-m; }
.ml-s     { margin-left: @spacing-s; }
.ml-xs    { margin-left: @spacing-xs; }
.ml-none  { margin-left: 0; }
.ml-auto  { margin-left: auto; }

// Vertical (top + bottom)
.mv-xxl   { .mt-xxl();  .mb-xxl();  }
.mv-xl    { .mt-xl();   .mb-xl();   }
.mv-l     { .mt-l();    .mb-l();    }
.mv-m     { .mt-m();    .mb-m();    }
.mv-s     { .mt-s();    .mb-s();    }
.mv-xs    { .mt-xs();   .mb-xs();   }
.mv-none  { .mt-none(); .mb-none(); }
.mv-auto  { .mt-auto(); .mb-auto(); }

// Horizontal (left + right)
.mh-xxl   { .mr-xxl();  .ml-xxl();  }
.mh-xl    { .mr-xl();   .ml-xl();   }
.mh-l     { .mr-l();    .ml-l();    }
.mh-m     { .mr-m();    .ml-m();    }
.mh-s     { .mr-s();    .ml-s();    }
.mh-xs    { .mr-xs();   .ml-xs();   }
.mh-none  { .mr-none(); .ml-none(); }
.mh-auto  { .mr-auto(); .ml-auto(); }
