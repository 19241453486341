i.pipeline-status-open {
  color: @pipeline-state-open;
}

i.pipeline-status-success {
  color: @pipeline-state-success;
}

i.pipeline-status-blocked {
  color: @pipeline-state-blocked;
}

i.pipeline-status-in-progress {
  color: @pipeline-state-in-progress;
}

i.pipeline-status-retry {
  color: @state-danger-text;
}

i.pipeline-status-step-success {
  color: @state-success-text;
}

i.pipeline-status-step-error {
  color: @state-danger-text;
}

i.pipeline-status-step-stalled {
  color: @action-color;
}

i.pipeline-status-step-skipped {
  color: @state-info-text;
}

div#pipeline div:last-child i:last-child {
  display: none;
}
