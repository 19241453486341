.banner {
  display: flex;
  flex-direction: column;
  z-index: 510;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  margin: 0 auto;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  gap: 1em;
  overflow: hidden;

  .banner-description {
    display: none;
  }

  * > div {
    white-space: nowrap;
  }

  strong {
    font-size: 16px;
    white-space: nowrap;
  }

  .btn.primary {
    display: inline-block;
    box-shadow: none;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 16px;
    overflow: visible;

    color: @light1;

    &:hover {
      color: @light2;
    }
  }

  .btn.more-btn-transparent {
    overflow: visible;
    &:hover {
      background-color: rgba(17,24,39,0.05);
    }
  }

  &-error {
    background-color: @red-200;
    color: @red-900;

    .btn.primary {
      background-color: @red-600;
    }
  }

  &-warning {
    background-color: @orange-200;
    color: @orange-900;

    .btn.primary {
      background-color: @orange-600;
    }
  }

  &-info {
    background-color: @purple-200;
    color: @purple-900;

    .btn.primary {
      background-color: @purple-600;
    }
  }

  &-close {
    position: absolute;
    right: 16px;
  }

  @media (min-width: @screen-md) {
    flex-direction: row;
    height: 40px;
    .banner-description {
      display: inline;
    }
  }
}

.more-button-warning-banner {
  .more-btn(@orange-600);
  color: @light1;

  &:hover {
    color: @light2;
  }
}

.more-button-info-banner {
  .more-btn(@purple-600);
  color: @light1;

  &:hover {
    color: @light2;
  }
}
