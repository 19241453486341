// MORE-2180: Move all z-indexes to a dedicated less-file, with more structured values

.menu-wrapper {
  position: fixed;
  height: 100%;
  z-index: 500;
  width: @navbar-width;
  box-shadow: 2px 0 5px @menu-wrapper-box-shadow;
}

.menu-wrapper-banner {
  top: 80px;
  bottom: 0;
  height: auto;
}

.menu-header {
  width: auto;
  height: @navbar-height;
  background: @menu-header-color;

  .navbar-brand {
    padding: 14px 18px;
  }
}

#menu-navbar-wrapper {
  z-index: 1001;
  width: @navbar-width;
  background: @navbar-background;
  height: 100%;
  bottom: 0;
  overflow: auto;
  margin: 0;
  padding: 0;
  float: left;

  overflow-y: hidden;
  overflow-x: hidden;

  box-shadow: 2px 0 2px @navbar-box-shadow;
}

.menu-template-picker {
  width: @menu-picker-width;
  background-color: @collection-picker-background;
  height: 100%;
  float: left;
  left: @navbar-width;
  z-index: -1;
  position: absolute;
}

.portal-menu {

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding-left: 20px;
      padding-right: 10px;
      position: relative;
      &:hover {
        background-color: @navbar-item-hover-color;
      }

      a {
        display: block;
        margin: 0;
        padding-top: 10px;
        padding-bottom: 5px;
        cursor: pointer;
        text-overflow: ellipsis;
        width: 150px;

        color: @navbar-item-text-color;
        position: relative;
        overflow: hidden;
        font-size: 120%;
        white-space: nowrap;
        i {
          width: 26px;
          margin-bottom: 10px;
          margin-right: 10px;
        }

        &:hover, &.active {
          color: @navbar-item-hover-text-color;
        }

        &.locked {
          opacity: 0.5;
        }
      }

      ul.sub-menu {
        z-index: 2000;
        position: absolute;
        top: 0;
        left: @navbar-width;
        visibility: hidden;
        box-shadow: 2px 0px 5px @navbar-box-shadow;

        .sub-menu-item {
          background: @navbar-sub-background;
          &:hover {
            background: @navbar-sub-hover-color;
            a {
              color: contrast(@navbar-sub-hover-color) !important;
            }
          }
        }
      }

      ul.sub-menu:hover {
        visibility: visible;
      }
      &.hoverable:hover {

        ul.sub-menu {
          visibility: visible;
        }
      }
    }

    .menu-item-badge {
      position: absolute;
      right: 6px;
      top: 13px;
      padding-left: 4px;
      padding-right: 4px;
      font-weight: 300;
    }
  }
}

.portal-menu ul.portal-menu-top-section {
  margin-top: 10px;
}

.portal-menu .fa-tablet {
  font-size: 130%;
}

.menu-item-overlay {
  font-size: 70%;
  margin-left: -24px;
  margin-top: -10px;
  position: absolute;
}

.portal-menu ul.portal-menu-bottom-section {
  position: absolute;
  bottom: 0;
  width: @navbar-width;
  li {
    padding-right: 0;
  }
}

#customer-picker {
  .portal-menu();
  width: 100%;
  margin: 0;
  z-index: 1001;
  position: relative;

  .single-customer-display {
    background-color: #ffffff;
    text-align: center;
    padding: 6px 12px;
  }

  &.btn-group {
    display: block;
  }

  .multiple-customer-display:not(.open) {
    .input-group {
      display: none;
    }
  }

  .dropdown-menu {
    .create-customer-item {
      border-bottom: 1px solid @dark4;
    }
  }

  button {
    .no-radius();
    height: 35px;
    border: none;
    width: 100%;
    overflow: hidden;
  }

  .input-group {
    position: absolute;
    top: 34px;
    width: 100%;

    .input-group-addon {
      border-radius: 0;
    }
    input {
      margin-top: 1px;
    }
  }

  ul {
    .no-radius();
    margin: 34px 0 0 0;
    border: 0;

    li {
      margin: 0;
      padding: 0;
      &:hover, a:focus {
        color: @light2;
        background-color: @brand;
      }
      a {
        margin-left: 10px;
        width: auto;
      }
    }
  }

  .scrolling-menu {
    max-height: 360px;
    height: auto !important;
    overflow: scroll;
    width: 100%;
  }

  #customer-filter {
    .no-shadow();
    border: none;
  }
}

a#language-switch-menu-item {
  color: #7C7C7C;

  .active-language {
    color: #ffffff;
  }
}

@media (min-width: @screen-md) {
  .menu-wrapper-banner {
    top: 40px;
    bottom: 0;
    height: auto;
  }
}
