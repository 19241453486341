.more-block {
  position: relative;
  width: 220px;
  padding: 20px;
  border: 1px solid #d0d0d0;
  background: #f2f2f2;
  float: left;
  margin: 20px;
  border-radius: 4px;

  h4 {
    font-weight: bold;
  }

  .block-description {
    margin-bottom: 20px;
    color: @dark7;
  }

  .input-group {
    width: 100%;
  }

  .block-image {
    width: 180px;
    height: 75px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    i {
      font-size: 200%;
      color: #fff;
    }
  }

  .btn-block {
    position: absolute;
    bottom: 20px;
    width: 180px;
    left: 20px;
  }
}

.more-block-modal {
  padding: 0 20px;

  .more-block-close {
    position: absolute;
    right: 35px;
    top: 40px;
    width: 90px;
  }

  .more-block:first-of-type {
    margin-left: 0;
  }

  .more-block:last-of-type {
    margin-right: 0;
  }

  .modal-header {
    height: 86px;
    border: none;
  }

  .modal-content {
    overflow: hidden;
    border: 1px solid #000;
  }

  .modal-footer {
    border-top: none;
  }

  .modal-body {
    position: relative;
    overflow: hidden;
    padding: 14px;
  }
}