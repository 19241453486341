.view-management-top-bar {
  position: fixed;
  left: @navbar-width;
  right: 0;
  top: 0;
  background-color: @light2;
  height: @content-header-height;
  z-index: @z-index-view-management-top-bar;
  border-bottom: 3px solid @table-border-color;
  color: @dark6;

  .view-management-top-menu {
    position: absolute;
    bottom: 0;

    ul {
      padding: 0;
      margin: 0;
      list-style: outside none none;
      display: inline-block;

      li {
        position: relative;
        display: inline-block;

        a {
          font-size: 16px;
          padding: 0 18px 10px;
          display: block;
          color: @dark6;

          &:hover {
            color: @dark2;
          }

          @media (max-width: 788px) {
            padding-left: 8px;
            padding-right: 8px;
          }
        }

        &.disabled a {
          color: lighten(@brand, 20%);
          pointer-events: none;
        }
      }

      li span.menu-item-overlay {
        font-size: 60%;
        margin: 0;
        position: absolute;
        margin-top: -5px;
        float: right;
        color: @brand;
        text-transform: uppercase;
      }

      li.active {

        a {
          color: @brand;
          border-bottom: 3px solid @brand;
          padding-bottom: 7px;
        }
      }
    }

    .advanced-mode-button {
      position: relative;
      top: -4px;
      color: @dark6;
      border: 0;
    }
  }

  .view-management-top-title {
    h1, h2 {
      color: @fontdark;
    }
    padding: 10px 20px 0 20px;
    font-weight: normal;

    .subtitle {
      font-size: 13px;
      font-weight: 300;
    }
  }

  .view-management-top-owner {
    padding-left: 20px;
  }

  .view-management-top-status {
    position: absolute;
    bottom: 0;
    padding: 10px 20px 10px 20px;
    font-weight: normal;

    &.right {
      right: 0;
    }
  }
}

.view-management-content {
  position: relative;
  top: @content-header-height;

  .view-management-commands {
    position: fixed;
    right: 0;
    top: 106px;
    margin-right: 5px;
    z-index: @z-index-view-management-commands;
  }

  &.view-management-content-gutted {
    padding-top: 20px;
  }
}

.view-management-overview {
  #form-statusboard {
    width: 265px;
  }
  .form-sharing-row {
    margin-top: 10px;
    margin-left: 0
  }
  .form-sharing-row-details-button {
    .btn();
    .btn-success();
    .btn-block();
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-sharing-row-details-button-hidden-in-marketplace {
    .btn();
    .btn-block();
    color: #000000;
    background-color: white;
    border: 1px solid white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-sharing-row-details {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid @brand-success;
  }
  .form-sharing-row-details-hidden-in-marketplace {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid white;
  }
  .unshare-form-button {
    margin-top: 3px;
  }
}

.form-template-logo img {
  display: block;
  max-width: 100%;
  max-height: 100px;
  margin: auto auto 10px;
}

.form-info-wrapper {
  margin-right: 5px;
  position: absolute;
  left: 550px;
}

@media (max-width: 1060px) {
  .form-info-wrapper {
    margin-left: -30px;
  }
}

.form-history {
  max-height: calc(100vh - 125px);
  overflow-y: scroll;

  .current {
    background: @alert-success-bg;
    border-color: @alert-success-bg;
  }
  .draft {
    opacity: 0.6;
  }
  .active:not(.current) {
    background: lighten(@brand, 50%);
    border-color: lighten(@brand, 50%);
  }
}
