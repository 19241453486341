.page-heading {
  padding-left: @grid-gutter-width;
}

.page-content {
  top: @page-heading-height;
  left: @sidebar-width;
  padding-left: @grid-gutter-width;
  padding-right: @grid-gutter-width;
  right: 0;
}

.page-content-limited {
  max-width: 1200px;
}