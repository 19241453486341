.view-management-commands {
  .selectable-reorderable-dropdown {
    display: inline;

    .dropdown {
      right: 0;
      min-width: 175px;
    }
  }
}

.selectable-reorderable-dropdown {
  position: relative;

  .dropdown {
    background: white;
    margin-top: 5px;
    position: absolute;
    border: 1px solid lightgray;
    border-radius: 5px;

    ol {
      max-height: 400px;
      height: auto !important;
      overflow: scroll;
      padding: 0;

      li {
        padding: 5px;

        > div {
          padding: 0 30px 0 30px;
        }
        input {
          margin-left: -30px;
        }
        .reorder-handle {
          margin-right: -25px;
          opacity: 0;
        }

        a {
          color: @dark1;
          display: inline-block;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
        &:hover {
          background: @light1;

          .reorder-handle {
            opacity: 1;
          }
        }
      }
      padding-bottom: 40px;
      margin-bottom: 0;
    }

    button {
      position: absolute;
      bottom: 0;
    }
  }
}

.view-management-registrations {
  position: relative;

  .registrations-content {
    padding-bottom: 45px;

    .loading-indicator {
      text-align: center;
      padding: 32px 0 28px 0;
      border-bottom: 1px solid #DDD;
    }
  }

  .registration-export-controls {
    color: white;
    height: 45px;
    background-color: @dark6;
    padding: 5px 8px;
    position: fixed;
    bottom: 0;
    left: @sidebar-width;
    right: 0;
    margin-right: 0;

    .registration-export-all {
      margin-right: 10px;
    }
  }

  @media (max-width: 1400px) {
    .registration-export-controls {
      left: @sidebar-nav-collapsed-width;
    }
  }
}

.more-pagination-grid-container{
  overflow-x: scroll;
  padding-bottom: 95px;
}

.side-panel-registration-details {
  table {
    word-break: break-all;
    tr td:first-child {
      width: @side-panel-width / 2;
    }
  }
}

page-controller {
  right: 0;
  position: absolute;
  padding: 5px;
  width: 100%;
  text-align: right;

  .page-selector {
    .pull-left;
  }

  .total-items {
    display: inline;
    margin-right: 30px;
  }

  .page-picker {
    display: inline;
    select {
      display: inline;
      width: 50px;
    }
    .form-control {
      padding: 0 0 0 4px;
    }
  }

}

.mail-status-email {
  color: @dark1;
}

.mail-status-queued {
}

.mail-status-reject {
  color: @state-danger-text;
}

.mail-status-hard-bounce {
  color: @state-danger-text;
}

.mail-status-soft-bounce {
  color: #ff9e00;
}

.mail-status-success {
  color: @state-success-text;
}

.mail-status-bounce-description {
  font-weight: bold;
}
