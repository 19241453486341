h1 {
  color: @header-font-color;
  font-weight: 300;
}

h2 {
  color: lighten(@header-font-color, 10%);
  font-weight: 300;
}

h3 {
  color: lighten(@header-font-color, 10%);
  font-weight: 300;
}

.block-letters {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}

.block-letters-s {
  .block-letters();
  .grey();
  .fs-xxs();
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

.fs-xxs {
  font-size: 70%;
}
.fs-xs {
  font-size: 85%;
}
.fs-s {
  font-size: 90%;
}
.fs-l {
  font-size: 125%;
}
.fs-xl {
  font-size: 150%;
}

.grey    { color: grey; }
.success { color: @success;}
.warning { color: @brand-warning;}
.danger  { color: @brand-danger;}
.branded { color: @brand; }
.action  { color: @action-color; }

.ta-center {
  text-align: center;
}
.ta-start {
  text-align: start;
}
.bold {
  font-weight: bold;
}
