.faq .panel {
  margin-bottom: 10px;
}

.faq .panel-title {
  color: @fontdark;
}

.faq .panel-body {
  //  background-color: @body-bg;
  color: @fontdark;
}

.faq-search-row {
  height: 100%;
  overflow: hidden;

  .faq-search-wrapper {
    .search-wrapper();
    background: @body-bg;
    padding-right: 20px;

    .faq-search {
      // remove browser input markup
      border: 1px solid @dark3;
      background: @light2;
      width: 100%;
      padding-right: 60px;
    }
  }
}

// Move FreshChat a bit left and down, to not overlap our Help button
#hubspot-messages-iframe-container {
  z-index: @z-index-more-chat !important;
}

.ask-question-modal {
  .chat-status {
    position: absolute;
    right: 20px;
    top: 30px;
    .chat-online {
      color: @success;
    }
    .chat-offline {
      color: @error;
    }
  }
  .modal-content {
    width: 812px;
  }
  .more-block {
    min-height: 220px;
  }
}
