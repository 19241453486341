.success-bg {
  background: @alert-success-bg;
  border-color: @alert-success-bg;
}

.warning-bg {
  background: @alert-warning-bg;
  border-color: @alert-warning-bg;
}

.active-bg {
  background: lighten(@brand, 50%);
  border-color: lighten(@brand, 50%);
}

.bg-offwhite {
  background-color: @light5;
}

bg-grey {
  background-color: @light5;
}