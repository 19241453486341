.user-info {
  margin-left: 40px;
  width: 350px;

  .readonly-info-key {
    width: 80px;
  }
  .readonly-info-value {
    width: 270px;
  }
}

.panel-heading .panel-title .group-name {
  width: 165px;
}

.users-select-all {
  position: absolute;
  top: 15px;
  right: 15px;
}

.group-platform-permissions-locked {
  background: @light3;
  padding: 4px 8px;
  .checkbox {
    color: @dark6;
  }
}