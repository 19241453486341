section#signup {
  .signup-background {
    background-image: url('../../shared/images/segments/background.png');
    height: 100%;
    width: 100%;
    background-size: cover;
    background-clip: border-box;
    background-attachment: fixed;
    position: absolute;
  }

  .center {
    min-height: 100%;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  .btn-signup {
    background-color: @light2;
    border-color: @dark7;
    color: @brand;
  }

  a {
    text-decoration: underline;
  }
}
