.customer-branding {
  margin: 50px;
  max-width: 1200px;

  .branding-segment {
    clear: both;
    font-size: 18px;
    line-height: 1.6em;

    img {
      display: block;
      margin-bottom: 60px;
      max-width: 100%;
      max-height: 500px;
    }
    .branding-image {
      max-width: 49%;
      overflow: hidden;
    }
    .branding-info {
      margin-top: 0;
      max-width: 49%;
      padding: 2em;

      p {
        font-weight: 300;
      }
    }
    .block-letters {
      border-bottom: 1px solid @brand;
    }

    &:nth-child(odd) .branding-info {
      float: right;
    }
    &:nth-child(odd) .branding-image {
      float: left;
    }
    &:nth-child(even) .branding-info {
      float: left;
    }
    &:nth-child(even) .branding-image {
      float: right;
    }
  }
}