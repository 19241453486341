// Bootstrap 3.0 modal hack
.modal {
  display: block;
}

body {
  .modal-open {
    margin-right: 0;
  }
}

.modal-body:before,
.modal-body:after {
  display: table;
  content: " ";
}

.modal-header:before,
.modal-header:after {
  display: table;
  content: " ";
}

// Footer (for actions)
.modal-footer {
  button, .btn, a {
    min-width: 90px;
  }

  // Properly space out buttons
  .btn + .btn, button {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

.modal-fullscreen {
  @modal-header-height: 90px;

  .modal-header {
    height: @modal-header-height;
    padding: 0 16px;
    position: relative;
    background-color: @light2;
    border-bottom: 1px solid @dark4;
    font-size: 12pt;

    .modal-title-center {
      .text-center();
      margin-top: 30px;
    }

    .modal-action {
      position: absolute;
      top: 35px;
      right: 20px;
    }

    .back-btn {
      position: absolute;
      top: 10px;
      padding: 20px;
    }
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .modal-body {
    min-width: 480px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    &.modal-body-lg {
      max-width: 1200px;
    }
  }

  .modal-content {
    min-height: 100%;
    border-radius: 0;
    overflow: hidden;
    background-color: @light6;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
  }
}
