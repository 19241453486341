
.table.more-content-table {
  background: @light2;

  margin-bottom: 0;

  thead {
    background: @light3;
    color: #B9B9B9;

    th {
      cursor : pointer;
      min-width: 30px;
      &:first-child {
        min-width: 70px;
      }
    }

    .search-row .content-column {
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      td {
        color: @dark6;
      }

      &.active-row {

        background: lighten(@action-color, 5%);

        td {
          border-color: @more-content-table-active-row-border-color;
          color: @light2;
        }
      }
    }

    tr:nth-child(even) {
      background: @light3;

      &.active-row {
        background: @action-color;
      }
    }
  }

  th.toggle-column, td.toggle-column {
    width: 30px;
    text-align: center;
  }
}
