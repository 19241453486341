@import "../../../../node_modules/ionicons/less/ionicons.less";

@ionicons-font-path: "../../../node_modules/ionicons/fonts";

.application-wrapper.customer-portal {

  .usage-monthly-chart {
    width: 100%;
    height: 300px;
  }

  .usage-page-content {

    .usage-statistic-orb {
      margin: auto;
      width: @usage-statistic-orb-size;
      height: @usage-statistic-orb-size;
      text-align: center;
      padding-top: 20px;
      border-radius: 50%;
      border: 1px solid @dark7;
    }

    .usage-statistic-orb-wrapper {
      text-align: center;

      .orb-subtitle {
        h3 {
          margin-bottom: 0;
        }
        p {
          color: @dark7;
        }
      }
    }
  }

  .dropdown-menu-dynamic-recipients {
    overflow-x: scroll;
    width: 500px;
  }

  .dynamic-recipient-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
    display: inline-block;
  }

  i.bordered-circle-icon {
    background: black;
    padding: 1px;
    border-radius: 9px;
    width: 16px;
    height: 16px;
    text-align: center;
  }
}