.plan-content {
  min-height: 160px;
  img {
    border-radius: 4px;
    @media (max-width: @screen-md) {
      max-width: 200px;
      position: absolute;
      right: 10px;
    }
  }
  div.plan-button {
    text-align: right;
    @media (max-width: @screen-md) {
      position: absolute;
      right: 20px;
      margin-top: -30px;
    }
  }
  div.plan-details {
    display: inline-block;
    .monthly-plan {
      font-weight: bold;
      font-size: 120%;
      margin-bottom: 0;
    }
    .pricing {
      margin-bottom: 18px;
      .name {
        font-size: 250%;
        font-weight: 300;
        margin-left: -3px;
      }
    }
    .trial {
      margin-bottom: 0;
      font-size: 90%;
      .trial-days {
        font-weight: bold;
      }
    }
  }
  div.plan-usage {
    display: inline-block;
    vertical-align: top;
    color: @dark7;
    font-weight: 300;
  }
}

.plan-card {
  font-weight: 300;
  min-height: 340px;
  h3 {
    color: @dark7;
    margin-top: 0;
    font-size: 18px;
  }
  .bottom-block {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 30px;
  }
}

.plan-usage-block {
  .plan-card();
  .block-content {
    text-align: center;
    height: 250px;
    margin-top: 20px;
  }
}

.plan-action-block {
  .plan-card();
  .content {
    padding-top: 10px;
  }
  small {
    .pull-right();
    margin-top: 1px;
  }
  table {
    width: 100%;
    tr {
      font-weight: 300;
    }
    td {
      padding-top: 4px;
    }
    tr td:nth-child(2) {
      text-align: right;
    }
    .totals {
      font-weight: 400;

      td:nth-child(2) {
        border-top: 1px solid;
      }
    }
  }
}

.credit-card-block {
  padding-bottom: 10px;
  img, i {
    vertical-align: top;
    width: 30px;
  }
  .credit-card-actions {
    margin-top: -5px;
    .make-card-default {
      margin-left: 34px;
    }
    .delete-card {
      margin-left: 10px;
    }
  }
}

// Stripe
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
