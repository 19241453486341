.tour-modal {
  .modal-fullscreen();

  // HEADER
  .modal-header {
    .logo {
      position: absolute;
      height: 36px;
      top: 24px;
    }

    .onboarding-steps {
      min-width: 600px;
      max-width: 800px;
      height: 100%;
      position: relative;
      margin: 0 auto;
      text-align: center;

      .onboarding-step {
        height: 100%;
        line-height: (@modal-header-height - 4px);
        transition: all ease-in-out 0.3s;

        color: @dark4;
        border-color: @dark4;

        .step-number {
          display: inline-block;
          width: 28px;
          height: 28px;
          padding: 3px;
          margin-right: 8px;
          border-radius: 50%;

          background: transparent;
          border-width: 3px;
          border-style: solid;
          border-color: inherit;

          font: 15px Arial, sans-serif;
          font-weight: bold;
          text-align: center;

          .checkmark {
            display: none;
          }
        }

        &.active {
          color: @dark7;
          border-color: @dark7;
          font-weight: bold;
        }

        &.completed {
          color: @brand;
          border-color: @brand;

          .index {
            display: none;
          }
          .checkmark {
            display: inline-block;
          }
        }
      }

      @media (max-width: @screen-md) {
        min-width: 240px;
        width: 20%;

        .onboarding-step .step-description {
          display: none;
        }
      }
    }

    a.skip-onboarding {
      position: absolute;
      top: 32px;
      right: 30px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modal-body {
    .animate-switch-container {
      position: relative;
      height: auto;
      min-height: 600px;
    }
    .animate-switch {
      padding: 10px;
    }
    .animate-switch.ng-animate {
      -webkit-transition: opacity ease-out 0.5s;
      transition: opacity ease-out 0.5s;

      &.ng-leave {
        display: none;

        &.ng-leave-active {
          opacity: 0;
        }
      }

      &.animate-switch.ng-enter {
        opacity: 0;

        &.ng-enter-active {
          opacity: 1;
        }
      }
    }
  }

  .step {
    width: 100%;

    .step-wrapper {
      max-width: @screen-md-min;
      margin: auto;
      text-align: center;
    }

    h1 {
      color: @dark7;
      font-weight: bold;
      text-align: center;
      margin: 24px 0px 40px 0px;
    }

    .tour-section {
      margin-top: 32px;
      margin-bottom: 32px;
      clear: both;
    }

    .tour-form {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      .tour-input-group {
        text-align: left;
        margin-bottom: 24px;

        label {
          font-size: 12pt;
        }

        input {
          height: 50px;
        }

        .ui-select-container span {
          height: 50px;
          &.ui-select-match-text, &.ui-select-placeholder {
            position: absolute;
            padding: 7px;
          }
        }

        input[type=checkbox] {
          width: 16px;
          height: 16px;
          margin: 16px 12px 16px 4px;
        }

        .tour-newsletter-checkbox {
          margin-left: 2px;
          cursor: pointer;
        }
      }
    }

    .description {
      font-size: 120%;
      color: @dark7;
    }

    .tour-block-img + .description {
      width: 100%;
      position: absolute;
      bottom: 20px;
    }

    // STEP 1
    .fa-8x {
      font-size: 8em;
    }

    .row + .btn {
      margin-top: 40px;
    }

    .graphic {
      height: 120px;

      .row {
        position: relative;
        margin: 0 auto;
      }
      .row-2c {
        width: 200px;
      }
      .row-3c {
        width: 240px;
      }

      i.fa-4x {
        margin-top: 12px;
      }
      p {
        font-size: 10pt;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        padding: 16px 0px;
      }
    }

    // STEP 4
    .tour-select-form-type {
      display: flex;
      justify-content: center;
      margin-top: 56px;

      .tour-column {
        .make-md-column(6);
        width: 40% !important;

        .tour-column-block {
          position: relative;
          height: 250px;
        }

        .clickable-block {
          background: @light3;
          padding: 15px 30px;
          border-radius: 5px;
          border: 1px solid darken(@light1, 10%);
          border-bottom-width: 5px;
          position: relative;
          cursor: pointer;

          &:hover {
            background: @light2;
          }

          .block-image {
            position: absolute;
            right: 30px;
            top: 30px;
          }
        }

        &:nth-child(4n) {
          clear: left;
        }
      }
    }

    // STEP 5
    .tour-plugin-divider {
      border-bottom: 1px solid @dark4;
      margin: 40px 0px 44px 0px;
    }

    .tour-plugin-wrapper {
      position: absolute;
      width: 400px;
      text-align: left;

      .tour-plugin-details {
        position: absolute;
        top: -20px;
        left: 110px;
      }
    }

    // STEP 6
    .center-buttons button {
      margin-left: 8px;
      margin-right: 8px;
    }

    .tour-block-img {
      position: absolute;
      top: 0;
      width: 100%;
      overflow: hidden;
      margin-top: 20px;
      img {
        display: block;
        margin: auto auto -50px;
      }
      .fa {
        font-size: 1000%;
      }
    }
  }
}
