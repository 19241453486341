// All
.pa-xxl   { padding: @spacing-xxl; }
.pa-xl    { padding: @spacing-xl; }
.pa-l     { padding: @spacing-l; }
.pa-m     { padding: @spacing-m; }
.pa-s     { padding: @spacing-s; }
.pa-xs    { padding: @spacing-xs; }
.pa-none  { padding: 0; }

// Top
.pt-xxl   { padding-top: @spacing-xxl; }
.pt-xl    { padding-top: @spacing-xl; }
.pt-l     { padding-top: @spacing-l; }
.pt-m     { padding-top: @spacing-m; }
.pt-s     { padding-top: @spacing-s; }
.pt-xs    { padding-top: @spacing-xs; }
.pt-none  { padding-top: 0; }

// Right
.pr-xxl   { padding-right: @spacing-xxl; }
.pr-xl    { padding-right: @spacing-xl; }
.pr-l     { padding-right: @spacing-l; }
.pr-m     { padding-right: @spacing-m; }
.pr-s     { padding-right: @spacing-s; }
.pr-xs    { padding-right: @spacing-xs; }
.pr-none  { padding-right: 0; }

// Bottom
.pb-xxl   { padding-bottom: @spacing-xxl; }
.pb-xl    { padding-bottom: @spacing-xl; }
.pb-l     { padding-bottom: @spacing-l; }
.pb-m     { padding-bottom: @spacing-m; }
.pb-s     { padding-bottom: @spacing-s; }
.pb-xs    { padding-bottom: @spacing-xs; }
.pb-none  { padding-bottom: 0; }

// Left
.pl-xxl   { padding-left: @spacing-xxl; }
.pl-xl    { padding-left: @spacing-xl; }
.pl-l     { padding-left: @spacing-l; }
.pl-m     { padding-left: @spacing-m; }
.pl-s     { padding-left: @spacing-s; }
.pl-xs    { padding-left: @spacing-xs; }
.pl-none  { padding-left: 0; }

// Vertical (top + bottom)
.pv-xxl   { .pt-xxl();  .pb-xxl(); }
.pv-xl    { .pt-xl();   .pb-xl(); }
.pv-l     { .pt-l();    .pb-l(); }
.pv-m     { .pt-m();    .pb-m(); }
.pv-s     { .pt-s();    .pb-s(); }
.pv-xs    { .pt-xs();   .pb-xs(); }
.pv-none  { .pt-none(); .pb-none(); }

// Horizontal (left + right)
.ph-xxl   { .pr-xxl();  .pl-xxl(); }
.ph-xl    { .pr-xl();   .pl-xl(); }
.ph-l     { .pr-l();    .pl-l(); }
.ph-m     { .pr-m();    .pl-m(); }
.ph-s     { .pr-s();    .pl-s(); }
.ph-xs    { .pr-xs();   .pl-xs(); }
.ph-none  { .pr-none(); .pl-none(); }
