.form-simulator {
  .view-editor-preview();
  position: relative;
  width : auto;
}

.form-simulator-container {
  .view-editor-preview-container();
  top: 0;
  left: 0;
  position: relative;
}

.form-simulator-container-wrapper.ionic {
  .view-editor-preview-container-wrapper();
  margin-left: 10px;
  position: relative;
  max-height: @view-editor-preview-min-height + 30px;
  overflow: hidden;
}

.form-simulator-pop-navigation-pane {
  .pop-navigation-pane();
  margin-left: -15px;
  left: 0;
  position: absolute;
}

.form-simulator-field-container {
  .template-drop-box();
  padding : 10px;
  overflow: scroll;
  overflow-x: hidden;
  max-height: @view-editor-preview-min-height;
}

.ignored {
  pointer-events: none;
  opacity: 0.2;
}

.date-modal {
  .date-picker > table {
    margin: 0 auto;
  }
  .time-picker {
    margin: 0 auto;
  }
}

.ionic {
  .input-label {
    max-width: 100% !important;
  }

  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', sans-serif !important;

  .more-form-item.widget-interactive {
    padding: 12px 0;
    border: 1px solid #CCC;
    background-color: white;
  }
  .more-form-item.widget-interactive + .more-form-item.widget-interactive {
    border-top-width: 0px;
  }

  .more-form-item {
    .list.card {
      margin: 0 !important;
      padding: 0;
      border-radius: 0px;
      background-color: transparent;
      box-shadow: none;
    }

    .item {
      &.item-divider {
        padding: 0 12px;
        background-color: transparent !important;
        border-bottom: 0;
      }

      &:not(.item-divider) {
        border-top-width: 0;
      }

      &.item-input-inset {
        padding: 0;
        margin: 0 12px;
      }
    }
  }

  .button-bar .button.button-positive.button-outline {
    color: @brand;
    border-color: @brand;
  }
}
