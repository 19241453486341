.search-wrapper {
  height: @item-height;
  background: @dark4;

  input {
    // remove browser input markup
    border: none;
    box-shadow: none;
    height: 30px;
    background: @light4;
    border-radius: 20px;
    width: @search-width;
    margin: @search-margin;
    outline: none;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 300;
  }

  i {
    color: @dark3;
    position: absolute;
    margin: 18px 20px;
    pointer-events: none;
  }
}
