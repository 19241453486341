body, html {
  height: 100%;
}

body.modal-open {
  margin-right: 0;
}

.content-wrapper {
  position:absolute;
  left: @navbar-width;
  right: 0;
}

.content-wrapper-banner {
  top: 80px;

  .view-management-top-bar {
    top: 80px;
  }
}

.block {
  display: inline-block
}
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-direction-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-block-3 {
  .truncate();
  .white-space-normal();
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.white-space-normal {
  white-space: normal;
}

::-webkit-scrollbar {
  display: none; // Hide all scrollbars on (Windows) Chrome
}

* {
  -ms-overflow-style: none; // Hide all scrollbars on Windows Edge (like on MacOS :D)
  scrollbar-width: none; // Hide all scrollbars onn Firefox
}

@media (min-width: @screen-md) {
  .content-wrapper-banner {
    top: 40px;

    .view-management-top-bar {
      top: 40px;
    }
  }
}
