.simple-tabs {
  margin: 0;
  padding: 0;
  display: inline-block;
  list-style: none;
  background: #eee;
  overflow: hidden;
}

.simple-tab {
  margin: 0;
  display: inline-block;

  a {
    text-decoration: none;
    padding: 5px 10px;
    display: inline-block;
  }

  &.active {
    background: @brand-primary;

    a {
      color: #fff;
    }
  }
}