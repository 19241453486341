.placeholder-dropping-item:first-child {

  .border-top-radius(30px);
}

.template-drop-box {
  position: absolute;
  width: @view-editor-preview-width;
  min-height: @view-editor-preview-min-height;

  border: 10px solid @dark6 !important;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0 0.5rem 0.5rem 0 @dark8;
}

.template-drop-area {
  padding-left: 0;
  min-height: 60px;
  list-style: none;
  width: 100%;
  margin-bottom: 0;
}

.template-drop-area:empty {
  border: 3px dashed #d3d3d3;
  background: #f9f9f9;
  .border-top-radius(30px);
}

.form-field {
  margin-bottom: 3px;
  .transition(height 100ms);
  &:last-child {
    margin-bottom: 0;
  }
}

.element-preview .required-indicator {
  color: red;
}

.view-editor-header-wrapper {
  z-index: @z-index-view-management-top-bar;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: @content-header-height;

  .navigation {
    position: absolute;
    width: @navbar-width;
    background-color: @brand;
    height: @content-header-height;
    border-bottom: 3px solid #dddddd;

    .navigation-back {
      position: absolute;
      bottom: 0;
      width: 100%;

      a {
        cursor: pointer;
        font-size: 110%;
        padding: 15px;
        display: block;
        color: white;

        &:hover {
          color: @light1;
        }
      }
    }
  }

  .view-editor-header {
    position: absolute;
    left: @navbar-width;
    height: @content-header-height;
  }

  .view-management-top-bar {
    left: @navbar-width !important;
  }
}

.toolbox-widget {
  width: @toolbox-widget-height;
  height: @toolbox-widget-height;
  border: 1px solid @toolbox-widget-border-color;
  cursor: move;
  border-radius: 6px;
  float: left;
  position: relative;

  .info-icon {
    i {
      margin-right: 5px;
    }
  }
  color: #aaa;

  &:hover {
    text-decoration: none;
    background: darken(#fff, 5%);
    .box-shadow(0 0 5px darken(black, 5%));
  }

  margin: @toolbox-widget-margin;
}

.toolbox-widget {
  .toolbox-widget-top-box {
    height: 10px;
  }

  .toolbox-widget-icon {
    position: absolute;
    width: 100%;
    font-size: 5.5em;
    text-align: center;
    line-height: 1;
    vertical-align: top;
  }

  .toolbox-widget-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    text-align: center;
    color: @toolbox-widget-text-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.field-picker-widget-icon {
  font-size: 2em;
}

.toolbox-widget-info {
  margin-right: 3px;
}

.ui-draggable-dragging {

  position: relative;
  z-index: 2000;

  div {
    position: relative;
    z-index: 2000;
  }

  a {
    position: relative;
    z-index: 2000;
    background: darken(@header-background, 10%);
  }

}

.widget-type-button-group-wrapper {
  text-align: center;
}

.widget-type-button-group {
  margin-bottom: 10px;
}

.view-editor-preview {
  width: @view-editor-preview-width + 40px;
  position: absolute;
}

.view-editor-preview-container-wrapper {
  margin-left: 30px;
  width: 100%;
  min-width: @view-editor-preview-width;
  position: absolute;
  min-height: 710px;
}

.pop-navigation-pane {
  position: absolute;
  top: 370px;
  left: 28px;
}

.view-editor-preview-container {
  position: absolute;
  top: @grid-gutter-width;
  left: @grid-gutter-width;
}

.template-drop-box.animate-enter, .template-drop-box.animate-leave {
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
  transition: 1s all;
  position: relative;
  display: block;
}

.template-drop-box.animate-enter.animate-enter-active, .template-drop-box.animate-leave {
  margin-left: -100%;
  width: 200%;
}

.template-drop-box.animate-leave.animate-leave-active, .template-drop-box.animate-enter {
  margin-left: -0%;
}

.mobile-phone-background {
  font-size: 1200px;
  line-height: 880px;
  display: block;
  margin: @grid-gutter-width;
  position: absolute;
}

.view-editor-sliding-panel-drop {
  z-index: 1010;

  opacity: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.ng-hide-add, &.ng-hide-remove {
    .transition(all 0.5s);
    display: block !important;
  }

  &.ng-hide-remove, &.ng-hide-add.ng-hide-add-active {
    opacity: 0;
  }
  &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
  }
}

.view-editor-sliding-panel {
  position: fixed;
  width: 440px;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: 1060;

  &.ng-hide-add, &.ng-hide-remove {
    .transition(all 0.5s ease);
    display: block !important;
  }

  &.ng-hide-remove, &.ng-hide-add.ng-hide-add-active {
    right: -500px;
  }
  &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
    right: 0;
  }
}

.view-editor-control-box {
  z-index: @z-index-view-editor-control-box;
  margin-left: @view-editor-preview-width + 120px;
  position: fixed;
  width: ~"calc(100% - 600px)";
  max-width: 730px;
  overflow-y: scroll;
  height: ~"calc(100% - 150px)";
  .box-shadow(0 0 5px darken(black, 5%));
  background-color: white;
  .transition(all 0.5s ease);
}

@media (max-width: @view-editor-responsive-width) {
  .enlarged {
    .view-editor-control-box {
      width: ~"calc(100% - 380px)";
      margin-left: @view-editor-preview-width - 100px;
    }
    .view-editor-control-box-enlarge-handle {
      left: 500px - 160px;
      z-index: @z-index-view-editor-control-box;
      i {
        .transition(all 0.5s ease);
        transform: rotateY(180deg);
      }
    }
  }
}

.view-editor-control-box-enlarge-handle {
  display: none;
  i {
    font-size: 40px;
    margin-top: 0;
    margin-left: 10px;
  }
  .transition(all 0.5s ease);
  @media (max-width: @view-editor-responsive-width) {
    height: 45px;
    width: 40px;
    position: fixed;
    display: block;
    border: 1px solid @toolbox-widget-border-color;
    background-color: white;
    border-right: 0;
    border-radius: 10px 0 0 10px;
    left: 500px;
    cursor: pointer;
    top: 165px;
  }
}

.view-editor-wrapper {
  position: absolute;
  overflow: auto;
  top: 150px;
  bottom: 0;
  width: 100%;

  .view-management-content {
    top: 0;
    overflow: visible;
  }
}

.view-editor-control-box-content {
  min-width: @view-editor-control-box-width;

  .list-group-item {
    border: 0;
  }

  .accordion-header i {
    margin: 0 5px 0 0;
  }
  .panel {
    margin-bottom: 0;
    border: 0;
    box-shadow: none;
    .panel-body {
      border-bottom: 0;
    }
  }
}

.select2-container {
  width: 100%;
}

.draggable-widget {
  float: left;
}

.file-bucket {
  padding: 30px;
  border: 1px dashed gray;
  text-align: center;
  color: gray
}

.file-bucket.dragover {
  background-color: #d3d3d3;
}

.widget-close {
  .close;
  color: @toolbox-widget-border-color;
  opacity: 1.0;
}

.view-editor-email {
  .accordion-group {
    margin: 20px;
    border: 1px solid @dark4;
  }

  .panel-default > .panel-heading {
    background-color: @light5;
  }
  .input-group-addon {
    border-radius: 0;
  }
  .input-group > .input-group {
    margin-bottom: 0px;
  }
  .dynamic-recipients-select {
    min-height: 34px;
    overflow-x: auto;
    display: block;
    input {
      padding-left: 10px;
    }
  }
  .dynamic-recipients-dropdown button {
    border-radius: 0;
    padding-bottom: 7px;
  }
  form {
    .input-group-addon {
      min-width: 95px;
      text-align: right;
      background-color: transparent;
    }
    .input-group {
      width: 100%;
      margin-bottom: 8px;
    }
    .input-tooltip {
      margin-top: -26px;
      margin-right: 10px;
      position: relative;
      z-index: 3;
    }
    .input-tooltip .input-tooltip-bullet {
      color: gray;
      font-size: 14px;
    }
    .remove-mail {
      margin-right: 10px;
    }
  }
  .more-btn-block {
    margin-top: 5px;
    min-height: 56px;
  }
  .more-btn-block .block-content {
    padding-top: 7px;
    padding-left: 70px;
    padding-right: 50px;
    word-break: break-all;
  }
  .mail-option-inactive {
    opacity: 0.5;
    .mail-option-checkbox {
      right: 29px;
      opacity: 1;
    }
  }
  .mail-option-checkbox {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 200%;
  }
  .pdf-header-logo {
    max-width: 150px;
    max-height: 60px;
  }
  .pdf-header-logo-input {
    display: none;
  }

  .reset-attachment {
    margin-top: 16px;
  }
}

.view-editor-settings {
  margin-right: 20px;
}

.view-editor-rules accordion {
  .panel-group .panel {
    margin-top: 0 !important;
    overflow: visible;
  }

  .panel-title {
    font-size: 14px;
  }

  .panel-body {
    border-top: 0 !important;
  }

  .panel {
    border-bottom: 0 !important;
  }

  .picker-default-option {
    color: gray;
  }

  .panel-heading {
    padding: 0;
  }

  .accordion-header {
    padding: 10px 15px;
  }
}

.view-editor .note-editable, .view-editor .note-codable {
  min-height: 300px;
}

.form-placeholders-well {
  .label {
    display: inline-block;
    margin: 0 2px;
  }
}

// Summernote fix for the summernote modals
.modal[aria-hidden=false] {
  display: none;
}

.note-editor.note-frame .note-statusbar .note-resizebar {
  display: none;
}

.ui-select-match .btn-link {
  color: @dark5;
}

.icon-picker-icon {
  margin: 4px auto;
  border: 1px solid @light4;
  padding: 5px;
}

.lookup_info {
  margin-top: 10px;
}

// responsive
@media (max-width: @screen-sm-max) {
  .toolbox-widget {
    width: 80px;
    height: 80px;
    margin: 5px;
  }

  .toolbox-widget-icon {
    font-size: 3em !important;
  }

  .view-editor-toolbox {
    width: 305px;
  }
}

@media (max-width: @screen-md-max) {
  .view-editor-control-box {
    margin-left: @view-editor-preview-width + 60px;
    width: ~"calc(100% - 540px)";
  }
}

.ui-select-bootstrap .form-control {
  padding-right: 5px;
}

.input-group-addon i.nestedSortable-handle {
  margin-left: 0;
}

.options-property {
  div[data-option-property] {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .nestedSortable-placeholder, .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.searchSettingsField {
  margin-bottom: 5px;
}

.pdfheader {
  margin-top: 12px;
  border: 1px solid @dark8;
  border-bottom-color: transparent;

  img {
    max-height: 150px;
  }
  .pdf-header-label {
    margin-left: 10px;
    margin-top: 10px;
    font-size: small;
    color: @dark6;
  }
}

.pdffooter {
  display: inline-block;
  width: 100%;
  border: 1px solid @dark8;
  border-top-color: transparent;

  .pdf-footer-label {
    margin-left: 10px;
    margin-top: 10px;
    font-size: small;
    color: @dark6;
  }

  .pdf-footer-text {
    margin-right: 20px;
    line-height: 70px;
  }
}

.view-editor-tab-title {
  h3 {
    margin-bottom: 5px;
  }
  .view-editor-tab-subtitle {
    color : @dark6;
    font-size: 12px;
  }
}

.form-editor-warning {
  border: 1px solid rgb(212, 201, 170);
  background-color: rgb(255, 251, 230);
  color: rgb(150, 128, 82);
  padding: 16px 12px;
  margin-bottom: 24px;
}
