@body-bg: @gray50;
@brand-primary: @brand;
@input-color-placeholder: @dark4;

@font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;
@header-font-color: @dark2;
@input-border: transparent;

@state-danger-text: #b94a48;
@state-danger-bg: #f2dede;
@state-danger-border: #b94a48;
@header-color: @brand;

@panel-border-radius: 0.4rem;
@panel-default-heading-bg: @light2;

@form-group-margin-bottom: 20px;