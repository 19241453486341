.modal.new-view-modal {
  .modal-dialog.modal-lg {
    width: 748px;
  }
}

.template-picker {

  &.template-selected {
    min-height: 700px;
  }
  .template-picker-forms {
    display: inline-block;
    left: 0;
    top: 0;
    width: 200px;

    .radio {
      font-size: 120%;

      input {
        margin-top: 4px;
      }
    }
  }

  .template-picker-preview {
    float: right;
    display: inline-block;
    padding-top: 10px;
  }

}