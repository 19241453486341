
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-left {
  justify-content: left;
}
.justify-content-right {
  justify-content: right;
}

.ta-editor {
  min-height: 300px;
  height: auto;
  overflow: auto;
  font-family: inherit;
  font-size: 100%;
}

.template-content-placeholder {
  .label;
  .label-default;
}

.popover {
  width: 350px;
}

.tooltip-inner {
  text-align: left !important;
  max-width: 350px;
}

.info-icon:hover .tooltip {
  opacity: 1 !important;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.help-text {
  color: @light1;
}

.no-radius {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.no-shadow {
  box-shadow: none;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
}

i.task-in-progress {
  color: @task-state-in-progress;
}

i.task-completed {
  color: @task-state-completed;
}

i.task-revoked {
  color: @task-state-revoked;
}
i.task-declined {
  color: @task-state-declined;
}

th.small {
  width: 10px;
  background-color: red;
}

.modal-ie-warning {
  margin: auto;
  min-width: 324px;
  background: rgba(0, 0, 0, 0.5);
  .browser-icons {
    text-align: center;
  }
}

.ui-select-container.ui-select-multiple {
  border: 1px solid rgb(211, 211, 211);
}

div[data-segment-select] .ui-select-bootstrap>.ui-select-choices {
  max-height: none;
}

.pull-right, .more-pull-right {
  float: right !important;
}

.pull-left, .more-pull-left {
  float: left !important;
}

ng-message {
  color: @state-danger-text;
}

.hidden-button-preventing-double-submit {
  display: none;
}

.ui-notification {
  i {
    font-size: 280%;
    padding: 20px;
  }
  .content {
    position: absolute;
    top: 20px;
  }
  .title {
    font-weight: bold;
    font-size: 1.1em;
  }
  .message {
    font-size: 0.9em;
  }
}

p.subtitle {
  color: @dark6;
  font-size: 13px;
}

.borderless td, .borderless th {
  border: none;
}

.nopadding {
  padding: 0;
}

.clickable {
  cursor: pointer;
}

.overflow-hidden    { overflow: hidden; }
.overflow-scroll    { overflow: scroll; }
.overflow-x-scroll  { overflow-x: scroll; }
.overflow-y-scroll  { overflow-y: scroll; }
.overflow-ellipsis  { text-overflow: ellipsis; }

.hover-shadow {
  &.active, &:hover {
    .box-shadow(0 0.5rem 0.5rem 0 @dark6);
  }
}

.hover-bg {
  &:hover {
    background-color: @light5;
  }
}
