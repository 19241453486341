.form-control {
  background: #fff;

  border: 1px solid #ddd;
  border-radius: @panel-border-radius;
  .box-shadow(0 1px 1px rgba(0, 0, 0, .05));

  &:focus {
    border: 1px solid #ddd;
    .box-shadow(1px 1px 0 0 #ccc);
  }
}
.ui-select-match {
  overflow: hidden;
}

div[data-country-select], .ui-select-container {
  &.form-control {
    padding: 0;
    border: 0;
  }
}

.form-control[readonly][data-date-picker] {
  background: #fff;
}