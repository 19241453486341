//
// Side Panels
// --------------------------------------------------

// .side-panel-open      - body class for killing the scroll
// .side-panel           - container to scroll within
// .side-panel-dialog    - positioning shell for the actual side panel
// .side-panel-content   - actual side-panel w/ bg and corners and shit

// Kill the scroll on the body

@zindex-side-panel-background: @zindex-modal-background;
@side-panel-title-padding: @modal-title-padding;
@side-panel-content-bg: @modal-content-bg;
@side-panel-inner-padding: @modal-inner-padding;
@side-panel-header-border-color: #bbb;
@side-panel-footer-border-color: #bbb;
@side-panel-title-line-height: @modal-title-line-height;
@side-panel-content-fallback-border-color: #bbb;
@side-panel-content-border-color: @modal-content-border-color;
@side-panel-content-border-color: #bbb;
@side-panel-width: 440px;
@side-panel-width-medium: 600px;
@side-panel-width-large: 768px;

.side-panel-open {
  overflow: hidden;
}

// Container that the side-panel scrolls within
.side-panel-main {
  .panel;
  .panel-default;
  border: 0px;

  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: 1060;
}

.side-panel {
  opacity: 1;
  .side-panel-main;
  width: @side-panel-width;
}

.sidepanel-large {
  width: @side-panel-width-large;
  @media (max-width: @screen-sm) {
    width: 100%;
  }
}

.sidepanel-medium {
  width: @side-panel-width-medium;
  @media (max-width: @screen-sm) {
    width: 100%;
  }
}

.side-panel-fill {
  .side-panel-main;
  left: 400px;
}

// Shell div to position the side-panel with bottom padding
.side-panel-dialog {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding: 10px;
  z-index: (@zindex-side-panel-background + 10);
}

// Actual side-panel
.side-panel-content {
  position: relative;
  height: 100%;
  background-color: @side-panel-content-bg;
  //  border: 1px solid @side-panel-content-fallback-border-color; //old browsers fallback (ie8 etc)
  //  border: 1px solid @side-panel-content-border-color;
  background-clip: padding-box;
  // Remove focus outline from opened side panel
  outline: none;
}

// Side Panel header
// Top section of the side panel w/ title and dismiss
.side-panel-header {
  padding: @side-panel-title-padding;
  border-bottom: 1px solid @side-panel-header-border-color;
  //  min-height: (@side-panel-title-padding + @side-panel-title-line-height);
}

// Close icon
.side-panel-header .close {
  margin-top: -2px;
}

// Title text within header
.side-panel-title {
  margin: 0;
  line-height: @side-panel-title-line-height;
}

// Side Panel body
// Where all side panelcontent resides (sibling of .side-panel-header and .side-panel-footer)
.side-panel-body {
  position: relative;
  padding: @side-panel-inner-padding;
}

// Footer (for actions)
.side-panel-footer {
  margin-top: 15px;
  margin-bottom : 20px;
  padding: (@side-panel-inner-padding - 1) @side-panel-inner-padding @side-panel-inner-padding;
  padding-bottom: 75px; // give some padding to make sure the side-panel scrolls and the 'help' button is visible
  text-align: right; // right align buttons
  .clearfix(); // clear it in case folks use .pull-* classes on buttons

  button, .btn, a {
    min-width: 90px;
  }

  // Properly space out buttons
  .btn + .btn, button {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Scale up the side panel
@media screen and (min-width: @screen-tablet) {

  .side-panel-dialog {
    left: 50%;
    right: auto;
    width: 600px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
