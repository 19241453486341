.more-btn (@background-color) when (lightness(@background-color) >= 60%) {
  color: darken(@background-color, 80%);
  &:hover {
    color: darken(@background-color, 90%);
  }
  span, &:disabled {
    color: darken(@background-color, 60%);
  }
}

.more-btn (@background-color) when (lightness(@background-color) < 60%) {
  color: lighten(@background-color, 80%);
  &:hover {
    color: lighten(@background-color, 90%);
  }
  span, &:disabled {
    color: lighten(@background-color, 60%);
  }
}

.more-btn (@background-color) {
  border-radius: @border-radius-base;
  border-width: 0;
  background-color: @background-color;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: @padding-base-vertical @padding-base-horizontal;
  .box-shadow(1px 1px 0 0 darken(@background-color, 10%));

  &:hover {
    background-color: lighten(@background-color, 5%);
    text-decoration: none;
  }

  &:active {
    background-color: lighten(@background-color, 5%);
    .box-shadow(inset 1px 1px 0 darken(@background-color, 5%));
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    .opacity(.65);
  }
}

.more-btn-lg {
  .btn-lg();
  white-space: normal;
  padding: 15px 20px;
}

.more-btn-sm {
  .btn-sm();
}

.more-btn-primary {
  .btn();
  .btn-primary();
}

.more-btn-primary-lg {
  .more-btn-primary();
  .more-btn-lg();
}

.more-btn-default {
  .more-btn(#ddd);
}

.more-btn-default-lg {
  .more-btn(#ddd);
  .more-btn-lg();
}

.more-btn-primary-sm {
  .more-btn-primary();
  .more-btn-sm();
}
.more-btn-default-sm {
  .btn-default();
  .more-btn-sm();
}

.more-btn-inverse {
  .more-btn(#333);
}

.more-btn-clear {
  .more-btn(#fff);
  border: 1px solid #ddd;
  color: @dark3;
}

.more-btn-block {
  margin: 5px 5px 5px 0;
  text-align: left;
  border: 1px solid @dark8;
  padding: 10px;
  color: gray;
  width: 100%;
  background-color: @light5;
  i.block-icon {
    margin-left: 10px;
    margin-right: 20px;
    font-size: 250%;
  }
}

.more-btn-action {
  .more-btn(@action-color);
  color: @light3;
  &:hover {
    color: @light2;
  }
}

.more-btn-action-lg {
  .more-btn-action();
  .more-btn-lg();
}

.btn.btn-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn.btn-flat-lg {
  .btn-lg();
  background: @action-color;
  color: @light3;
  border-radius: 0;
  &.inversed {
    background: @dark6;
  }
}

.fresh {
  background: @fresh-color;
  border-bottom: 3px solid darken(@fresh-color, 25%);
  color: @fresh-font;
}
.btn.btn-fresh-lg {
  .btn-lg();
  .fresh();
  border-radius: 30px;
  &:focus {
    outline:0;
  }
  &:hover:not(.active) {
    background: lighten(@fresh-color, 5%);
  }
  &.active {
    background: darken(@fresh-color, 25%);
    border-bottom: 3px solid darken(@fresh-color, 50%);
  }
}

.more-btn-transparent {
  .more-btn(#ffffff00);
  border: 0px;
  box-shadow: none;
  color: @dark1;
}

.btn {
  // MORE-3906: Fixed strange selection box when using Ladda
  overflow: hidden;
}

.button-bar>.button-outline {
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px;
}

// responsive
@media (max-width: 1000px) {
  .responsive-btn {
    width: 38px;
    overflow: hidden;
    font-size: 22px;
    padding: 0 8px;
    margin-left: 3px;
    span:not(.ladda-label, .ladda-spinner) {
      display: none;
    }
  }
}
