.easeIn {
  .transition(all 0.3s ease);
}

body.picker-visible {
  .content-wrapper {
    left: @sidebar-width;
  }

  .view-management-top-bar {
    left: @sidebar-width;
  }
  
  .menu-wrapper {
    width: @sidebar-width;
  }

  @media (max-width: 1400px) {
    .content-wrapper, .view-management-top-bar {
      left: @sidebar-nav-collapsed-width;
    }
    #menu-navbar-wrapper {
      width: @navbar-collapsed-width;
      .easeIn();

      &:hover {
        width: @navbar-width;
        .easeIn();

        .portal-menu .portal-menu-bottom-section {
          width : inherit;
          overflow : visible;
        }
      }
    }
    .menu-wrapper {
      width: @sidebar-nav-collapsed-width;
    }
    .menu-template-picker {
      padding-left: @navbar-collapsed-width;
      width: 100%;
      left: 0;
    }
    .portal-menu .portal-menu-bottom-section {
      width : @navbar-collapsed-width;
      overflow : hidden;
    }
  }
}
